<template lang="pug">
    div.step-1
        div.inner-block
            div.inputs-div
                p.text-averta-light-32 {{$t("welcome.to.platform")}}
                p.subtitle-averta-regular-18.grey-text {{$t("onboarding.step1.title")}}
                div.info-block
                    simple-input.user-info-input.readOnly.first-name(:placeholder="$t('firstName')" :initialValue="firstName")
                    simple-input.user-info-input.readOnly.last-name(:placeholder="$t('lastName')" :initialValue="lastName")
                    simple-input.user-info-input.readOnly(:placeholder="$t('email')" :initialValue="email")
                        template(v-slot:icon)
                            img.icon.icon-24(src="/img/svg/mail-icon.svg")
                    simple-input.user-info-input(:placeholder="$t('jobTitle')+'*'" :initialValue="jobTitle" @updateValue="updateJobTitle")
                        template(v-slot:icon)
                            img.icon.icon-24(src="/img/svg/job-icon.svg")
                    div.input-row.user-info-input(v-click-outside="hideTelephoneCountryCode" @click="phoneInputFocused = true")
                        img.icon.icon-24(src="/img/svg/phone-icon.svg")
                        div.telephone-input(:class="{'light-border': !phoneInputFocused}")
                            div.telephone-selected-wrapper(@click="showTelephoneCountryCode = !showTelephoneCountryCode")
                                p {{ dialCode ? '+' + dialCode : "" }}
                                    img.icon.icon-24(src="/img/svg/dropdown.svg" :class="{'clicked': showTelephoneCountryCode}")
                                div.selected-list(v-if="showTelephoneCountryCode")
                                    div(v-for="country of countriesData" @click="dialCode = country.dialCode")
                                        div.telephone-element
                                            p.country-name.subtitle-averta-regular-18 {{ country.name }}
                                            p.country-code.subtitle-averta-regular-18 {{ country.dialCode }}
                            input.input-form.subtitle-averta-regular-18(v-model="telephone" :placeholder="$t('phone')")
                    div.user-info-input
                        select-input-with-dropdown(:optionList="locationList" :placeholder="$t('location')" :initial-value="location" @setOption="setLocation")
                            template(v-slot:icon)
                                img.icon.icon-24(src="/img/svg/localisation-icon.svg")
                    div.user-info-input.last-item
                        select-input-with-suggestion(:optionList="filteredBusinessUnits" :placeholder="!department ? $t('businessUnit')+'*' : department" :initial-value="department ? department : $t('businessUnit')+'*'" @setOption="setBU" :class="{'error' : errorRequired}")
                            template(v-slot:icon)
                                img.icon.icon-24(src="/img/svg/bu-icon.svg")
                    p.subtitle-averta-regular-18.danger(v-if="errorRequired") {{$t('field.notification1')}}
                div.button-block
                    button.button.btn-primary-square(@click="confirm") {{$t('next')}}
                    p.text-averta-regular-20.contact-us Dear all, please contact us at knowledge@scor.com for any technical issue.

</template>

<script>
import getCountry from 'skillbase-component/src/components/user-profile/services/DefautCountry';
import allCountriesData from 'skillbase-component/src/components/common/helpers/CountryCodeHelper';

export default {
    name: 'Step1',
    data() {
        return {
            isEmpty: true,
            departmentQuery: '',
            department: '',
            location: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            email: '',
            telephone: '',
            // locationList: [],
            showLocationList: false,
            selectedLocation: false,
            showManagerList: false,
            showBUList: false,
            departmentId: false,
            dialCode: '',
            selectedTelephoneCountry: '',
            showTelephoneCountryCode: '',
            countriesData: allCountriesData.option,
            errorRequired: false,
            phoneInputFocused: false,
        };
    },
    components: {
        SimpleInput: () => import('skillbase-component/src/components/common/input/SimpleInput.vue'),
        SelectInputWithDropdown: () => import('skillbase-component/src/components/common/input/SelectInputWithDropdown.vue'),
        SelectInputWithSuggestion: () => import('skillbase-component/src/components/common/input/SelectInputWithSuggestion.vue')
    },
    computed: {
        jobTitleList() {
            return this.$store.getters['user-profile/list/jobs'];
        },
        user() {
            return this.$store.getters['user-profile/getUser'](this.$store.getters.user.username);
        },
        businessUnits() {
            return this.$store.getters['common/businessUnit/businessUnitList'];
        },
        locationList() {
            let locations = this.$store.getters['common/location/locations'];
            let toReturn = [];
            locations.forEach(location => {
                if (location.city) {
                    location.label = location.city + ', ' + location.country
                    toReturn.push(location)
                }
            })
            toReturn = toReturn.sort((a, b) => (a.country.toLocaleLowerCase() > b.country.toLocaleLowerCase() ? 1 : -1));
            return toReturn
        },
        filteredBusinessUnits() {
            let toReturn = [...this.businessUnits.sort(this.compare('label'))];
            this.businessUnits.forEach((businessUnit, index) => {
                if (this.departmentQuery && businessUnit.label.toLowerCase().indexOf(this.departmentQuery.toLowerCase()) != -1) {
                    toReturn.unshift(toReturn.splice(index, 1)[0]);
                }
            });
            return toReturn;
        },
    },
    watch: {
        department: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue == '') {
                    this.departmentId = false;
                }
            },
        },
        user: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    let user = newValue;
                    this.locationId = user.location && user.location['id'] ? user.location['id'] : false;
                    this.selectedLocation = user.location ? user.location : false;
                    this.location = user.location && user.location['id'] ? user.location.city + ', ' + user.location.country : '';
                    this.firstName = user.first_name ? user.first_name : '';
                    this.lastName = user.last_name ? user.last_name : '';
                    this.email = user.email ? user.email : '';
                    this.jobTitle = user.extraproperties['jobTitle'] ? user.extraproperties['jobTitle'] : '';
                    this.telephone = user.extraproperties['telephone'] ? user.extraproperties['telephone'] : '';
                    this.department = user.extraproperties['department'] ? user.extraproperties['department'] : '';
                    this.departmentId = user.department_id ? user.department_id : false;
                    this.$forceUpdate();
                }
            },
        },
        dialCode: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue != oldValue) {
                    this.selectedTelephoneCountry = this.dialCode;
                    this.$forceUpdate();
                }
            },
        },
    },
    methods: {
        updateJobTitle(value) {
            this.jobTitle = value
            this.$forceUpdate()
        },
        setLocation(element) {
            this.locationId = element.id;
            this.selectedLocation = element;
            this.location = element.city + ', ' + element.country;
            this.showLocationList = false;
            this.$forceUpdate();
        },
        hideTelephoneCountryCode() {
            this.showTelephoneCountryCode = false;
            this.phoneInputFocused = false;
        },
        findCountry(iso = '') {
            return this.countriesData.find((country) => country.iso2 === iso.toUpperCase());
        },
        setBU(businessUnit) {
            this.department = businessUnit.label;
            this.showBUList = false;
            this.departmentQuery = '';
            this.departmentId = businessUnit.id;
            this.$forceUpdate();
        },
        confirm() {
            this.errorRequired = false;

            if (!this.telephone || this.telephone === '...') {
                this.dialCode = null;
            }
            if (this.department) {
                this.$store
                    .dispatch('user-profile/updateUserInfos', {
                        username: this.user.username,
                        jobTitle: this.jobTitle ? this.jobTitle : null,
                        dialCode: this.dialCode,
                        telephone: this.telephone ? this.telephone : null,
                        locationId: this.selectedLocation.id,
                        department: this.department,
                        departmentId: this.departmentId,
                    })
                    .then((response) => {
                        this.$router.push('/step2');
                        this.$emit('close');
                    });
            } else {
                this.errorRequired = true;
            }
        },
        hideLocationList() {
            this.showLocationList = false;
        },
        hideBUList() {
            this.showBUList = false;
            this.departmentQuery = '';
            this.$forceUpdate();
        },
        compare(key) {
            //compare to order element by key
            return function (m, n) {
                return m[key].localeCompare(n[key]);
            };
        },
    },
    mounted() {
        this.$store.dispatch('common/businessUnit/fetchBusinessUnitList');
        this.$store.dispatch('user-profile/list/fetchAllJobs');
        this.$store.dispatch('common/location/fetchAllLocations');
        if (this.$store.getters.user.username) {
            this.$store.dispatch('user-profile/fetchUser', this.$store.getters.user.username);
        }
        const user = this.$store.getters['user-profile/getUser'](this.$store.getters.user.username)
        this.dialCode = user && user.extraproperties ? user.extraproperties.dialCode : ''

        getCountry().then((res) => {
            if (!this.dialCode) {
                this.dialCode = this.findCountry(res).dialCode;
            }
        });
    },
};
</script>

<style scoped lang="scss">
.step-1 {
    display: flex;
    justify-content: center;
    width: 100vw !important;
    margin-left: 0 !important;
    background: url('/img/welcomepage.png');
    height: 100vh !important;
    overflow: hidden !important;
    background-size: cover;
    padding-top: 0 !important;

    .inner-block {
        display: flex;
        align-items: center;
        padding: 50px 0;
        justify-content: center;

        .inputs-div {
            width: 490px;
            background: white;
            padding: 28px 35px;
            position: relative;
            border-radius: 4px;

            &::v-deep {
                .simple-input {
                    input {
                        width: 239px;
                    }
                }
            }


            .grey-text {
                color: var(--grey-1-color);
                margin-top: 3px;
            }

            .info-block {
                margin-top: 35px;
                max-height: calc(100% - 100px);
                display: flex;
                flex-direction: column;
                align-items: center;

                .input-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 24px;

                    img {
                        margin-right: 15px;
                    }

                    .telephone-input {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border: 2px solid var(--grey-1-color);
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border-radius: 5px;
                        position: relative;
                        width: 260px;

                        &.light-border {
                            border: 2px solid var(--grey-3-color);
                        }

                        input {
                            box-sizing: border-box;
                            padding: 4px 10px;
                            border: none;
                            border-radius: 3px;
                            width: 196px;

                            &::placeholder {
                                color: var(--grey-2-color);
                            }

                            &.preselected {
                                &::placeholder {
                                    color: var(--black-color);
                                }
                            }
                        }

                        .telephone-selected-wrapper {
                            position: relative;
                            border-right: 2px solid var(--grey-3-color);

                            & > p {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 3px 9px 3px 12px;
                                width: 100%;
                                box-sizing: border-box;
                                border: none;
                                border-radius: 3px;

                                img {
                                    width: 18px;
                                    height: 18px;
                                    margin-right: 0;
                                    margin-left: 3px;
                                }
                            }

                            .selected-list {
                                position: absolute;
                                background-color: white;
                                text-transform: capitalize;
                                border-radius: 3px;
                                width: 264px;
                                top: 32px;
                                left: -2px;
                                max-height: 150px;
                                overflow-y: auto;
                                z-index: 999;
                                box-shadow: 0 3px 6px var(--shadow-color);

                                .telephone-element {
                                    padding: 7px 12px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    cursor: pointer;

                                    &:hover {
                                        background: var(--primary-light-hover-color);

                                        p {
                                            color: var(--primary-color);
                                        }
                                    }
                                }

                                .country-name {
                                    white-space: nowrap;
                                    width: 70%;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    float: left;
                                }

                                .country-code {
                                    float: right;
                                }
                            }
                        }
                    }

                    .clicked {
                        transform: rotate(180deg);
                    }
                }

                .last-item {
                    margin-bottom: 0px !important;

                    &::v-deep .select-input-with-suggestion {
                        margin-bottom: 0px !important;
                    }
                }

                .user-info-input {
                    margin-bottom: 21px;

                    .select-input-with-dropdown {
                        ::v-deep input {
                            width: 240px;
                        }

                        ::v-deep .dropdown-list {
                            width: 264px;
                            right: 0;
                        }
                    }

                    .error {
                        &::v-deep {
                            input {
                                border: 2px solid var(--danger-color) !important;
                            }
                        }
                    }
                }

                .danger {
                    color: var(--danger-color);
                    position: absolute;
                    bottom: 115px;
                    left: 165px;
                }

                .readOnly {
                    pointer-events: none;
                }

                .first-name, .last-name {
                    margin-left: 32px;
                }

            }

            .button-block {
                margin-top: 70px;

                button {
                    float: right;
                }

                .contact-us {
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                    color: var(--white-color);
                }
            }


        }
    }
}
</style>
